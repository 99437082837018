import React, { useEffect, useState } from 'react';
import sampler from '../../Objects/axiosjson';
import "../../css/graphsme.css";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

const LineChart = () => {
  const [data, setData] = useState([]);
  const [subscriptionData, setSubscriptionData] = useState({});
  const [showSubscription, setShowSubscription] = useState(true);

  useEffect(() => {
    const addconfig = () => {
      sampler.get('/dashboardBattameez')
        .then(res => {
          setData(res.data.data);
          if (res.data.data && res.data.data.subscription) {
            setSubscriptionData(res.data.data.subscription);
          }
        })
        .catch(error => {
          console.error('Error fetching data:', error);
        });
    };
    addconfig();
  }, []);

  const toggleSubscription = () => {
    setShowSubscription(!showSubscription);
  };

  const getSafeValue = (data, key) => {
    return Array.isArray(data[key]) && data[key].length > 0
      ? data[key][0].count
      : "N/A"; // Fallback for empty or undefined data
  };

  return (
    <>
      <div className='graphsme'>
        <div className='graphsme01'>
          <p><h5>Subscription Data</h5>
          <span onClick={toggleSubscription}>
            {showSubscription ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />}
          </span></p>
          
          {showSubscription && subscriptionData && (
            <div className='graphsme02'>
              <p><span>Today's Subscription count:&nbsp;</span><b>{getSafeValue(subscriptionData, 'todaySubscription')}</b></p>
              <p><span>Yesterday's Subscription count:&nbsp;</span><b>{getSafeValue(subscriptionData, 'yesterdaySubscription')}</b></p>
              <p><span>Weekly Subscription count:&nbsp;</span><b>{getSafeValue(subscriptionData, 'weeklySubscription')}</b></p>
              <p><span>Biweekly Subscription count:&nbsp;</span><b>{getSafeValue(subscriptionData, 'biweeklySubscription')}</b></p>
              <p><span>Monthly Subscription count:&nbsp;</span><b>{getSafeValue(subscriptionData, 'monthlySubscription')}</b></p>
              <p><span>Quarterly Subscription count:&nbsp;</span><b>{getSafeValue(subscriptionData, 'quarterlySubscription')}</b></p>
              <p><span>Annual Subscription count:&nbsp;</span><b>{getSafeValue(subscriptionData, 'annualSubscription')}</b></p>
              <p><span>Life Subscription count:&nbsp;</span><b>{getSafeValue(subscriptionData, 'lifeSubscription')}</b></p>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default LineChart;
