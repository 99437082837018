import React from "react";
import AddchartIcon from '@mui/icons-material/Addchart';
function Listcountry(){
return [
    {"name":"Afghanistan","code":"AF"},
    {"name":"Aland Islands","code":"AX"},
    {"name":"Albania","code":"AL"},
    {"name":"Algeria","code":"DZ"},
    {"name":"American Samoa","code":"AS"},
    {"name":"Andorra","code":"AD"},
    {"name":"Angola","code":"AO"},
    {"name":"Anguilla","code":"AI"},
    {"name":"Antarctica","code":"AQ"},
    {"name":"Antigua and Barbuda","code":"AG"},
    {"name":"Argentina","code":"AR"},
    {"name":"Armenia","code":"AM"},
    {"name":"Aruba","code":"AW"},
    {"name":"Australia","code":"AU"},
    {"name":"Austria","code":"AT"},
    {"name":"Azerbaijan","code":"AZ"},
    {"name":"Bahamas","code":"BS"},
    {"name":"Bahrain","code":"BH"},
    {"name":"Bangladesh","code":"BD"},
    {"name":"Barbados","code":"BB"},
    {"name":"Belarus","code":"BY"},
    {"name":"Belgium","code":"BE"},
    {"name":"Belize","code":"BZ"},
    {"name":"Benin","code":"BJ"},
    {"name":"Bermuda","code":"BM"},
    {"name":"Bhutan","code":"BT"},
    {"name":"Bolivia","code":"BO"},
    {"name":"Bonaire, Sint Eustatius and Saba","code":"BQ"},
    {"name":"Bosnia and Herzegovina","code":"BA"},
    {"name":"Botswana","code":"BW"},
    {"name":"Bouvet Island","code":"BV"},
    {"name":"Brazil","code":"BR"},
    {"name":"British Indian Ocean Territory","code":"IO"},
    {"name":"Brunei Darussalam","code":"BN"},
    {"name":"Bulgaria","code":"BG"},
    {"name":"Burkina Faso","code":"BF"},
    {"name":"Burundi","code":"BI"},
    {"name":"Cambodia","code":"KH"},
    {"name":"Cameroon","code":"CM"},
    {"name":"Canada","code":"CA"},
    {"name":"Cape Verde","code":"CV"},
    {"name":"Cayman Islands","code":"KY"},
    {"name":"Central African Republic","code":"CF"},
    {"name":"Chad","code":"TD"},
    {"name":"Chile","code":"CL"},
    {"name":"China","code":"CN"},
    {"name":"Christmas Island","code":"CX"},
    {"name":"Cocos (Keeling) Islands","code":"CC"},
    {"name":"Colombia","code":"CO"},
    {"name":"Comoros","code":"KM"},
    {"name":"Congo","code":"CG"},
    {"name":"Congo, Democratic Republic of the Congo","code":"CD"},
    {"name":"Cook Islands","code":"CK"},
    {"name":"Costa Rica","code":"CR"},
    {"name":"Cote D'Ivoire","code":"CI"},
    {"name":"Croatia","code":"HR"},
    {"name":"Cuba","code":"CU"},
    {"name":"Curacao","code":"CW"},
    {"name":"Cyprus","code":"CY"},
    {"name":"Czech Republic","code":"CZ"},
    {"name":"Denmark","code":"DK"},
    {"name":"Djibouti","code":"DJ"},
    {"name":"Dominica","code":"DM"},
    {"name":"Dominican Republic","code":"DO"},
    {"name":"Ecuador","code":"EC"},
    {"name":"Egypt","code":"EG"},
    {"name":"El Salvador","code":"SV"},
    {"name":"Equatorial Guinea","code":"GQ"},
    {"name":"Eritrea","code":"ER"},
    {"name":"Estonia","code":"EE"},
    {"name":"Ethiopia","code":"ET"},
    {"name":"Falkland Islands (Malvinas)","code":"FK"},
    {"name":"Faroe Islands","code":"FO"},
    {"name":"Fiji","code":"FJ"},
    {"name":"Finland","code":"FI"},
    {"name":"France","code":"FR"},
    {"name":"French Guiana","code":"GF"},
    {"name":"French Polynesia","code":"PF"},
    {"name":"French Southern Territories","code":"TF"},
    {"name":"Gabon","code":"GA"},
    {"name":"Gambia","code":"GM"},
    {"name":"Georgia","code":"GE"},
    {"name":"Germany","code":"DE"},
    {"name":"Ghana","code":"GH"},
    {"name":"Gibraltar","code":"GI"},
    {"name":"Greece","code":"GR"},
    {"name":"Greenland","code":"GL"},
    {"name":"Grenada","code":"GD"},
    {"name":"Guadeloupe","code":"GP"},
    {"name":"Guam","code":"GU"},
    {"name":"Guatemala","code":"GT"},
    {"name":"Guernsey","code":"GG"},
    {"name":"Guinea","code":"GN"},
    {"name":"Guinea-Bissau","code":"GW"},
    {"name":"Guyana","code":"GY"},
    {"name":"Haiti","code":"HT"},
    {"name":"Heard Island and Mcdonald Islands","code":"HM"},
    {"name":"Holy See (Vatican City State)","code":"VA"},
    {"name":"Honduras","code":"HN"},
    {"name":"Hong Kong","code":"HK"},
    {"name":"Hungary","code":"HU"},
    {"name":"Iceland","code":"IS"},
    {"name":"India","code":"IN"},
    {"name":"Indonesia","code":"ID"},
    {"name":"Iran, Islamic Republic of","code":"IR"},
    {"name":"Iraq","code":"IQ"},
    {"name":"Ireland","code":"IE"},
    {"name":"Isle of Man","code":"IM"},
    {"name":"Israel","code":"IL"},
    {"name":"Italy","code":"IT"},
    {"name":"Jamaica","code":"JM"},
    {"name":"Japan","code":"JP"},
    {"name":"Jersey","code":"JE"},
    {"name":"Jordan","code":"JO"},
    {"name":"Kazakhstan","code":"KZ"},
    {"name":"Kenya","code":"KE"},
    {"name":"Kiribati","code":"KI"},
    {"name":"Korea, Democratic People's Republic of","code":"KP"},
    {"name":"Korea, Republic of","code":"KR"},
    {"name":"Kosovo","code":"XK"},
    {"name":"Kuwait","code":"KW"},
    {"name":"Kyrgyzstan","code":"KG"},
    {"name":"Lao People's Democratic Republic","code":"LA"},
    {"name":"Latvia","code":"LV"},
    {"name":"Lebanon","code":"LB"},
    {"name":"Lesotho","code":"LS"},
    {"name":"Liberia","code":"LR"},
    {"name":"Libyan Arab Jamahiriya","code":"LY"},
    {"name":"Liechtenstein","code":"LI"},
    {"name":"Lithuania","code":"LT"},
    {"name":"Luxembourg","code":"LU"},
    {"name":"Macao","code":"MO"},
    {"name":"Macedonia, the Former Yugoslav Republic of","code":"MK"},
    {"name":"Madagascar","code":"MG"},
    {"name":"Malawi","code":"MW"},
    {"name":"Malaysia","code":"MY"},
    {"name":"Maldives","code":"MV"},
    {"name":"Mali","code":"ML"},
    {"name":"Malta","code":"MT"},
    {"name":"Marshall Islands","code":"MH"},
    {"name":"Martinique","code":"MQ"},
    {"name":"Mauritania","code":"MR"},
    {"name":"Mauritius","code":"MU"},
    {"name":"Mayotte","code":"YT"},
    {"name":"Mexico","code":"MX"},
    {"name":"Micronesia, Federated States of","code":"FM"},
    {"name":"Moldova, Republic of","code":"MD"},
    {"name":"Monaco","code":"MC"},
    {"name":"Mongolia","code":"MN"},
    {"name":"Montenegro","code":"ME"},
    {"name":"Montserrat","code":"MS"},
    {"name":"Morocco","code":"MA"},
    {"name":"Mozambique","code":"MZ"},
    {"name":"Myanmar","code":"MM"},
    {"name":"Namibia","code":"NA"},
    {"name":"Nauru","code":"NR"},
    {"name":"Nepal","code":"NP"},
    {"name":"Netherlands","code":"NL"},
    {"name":"Netherlands Antilles","code":"AN"},
    {"name":"New Caledonia","code":"NC"},
    {"name":"New Zealand","code":"NZ"},
    {"name":"Nicaragua","code":"NI"},
    {"name":"Niger","code":"NE"},
    {"name":"Nigeria","code":"NG"},
    {"name":"Niue","code":"NU"},
    {"name":"Norfolk Island","code":"NF"},
    {"name":"Northern Mariana Islands","code":"MP"},
    {"name":"Norway","code":"NO"},
    {"name":"Oman","code":"OM"},
    {"name":"Pakistan","code":"PK"},
    {"name":"Palau","code":"PW"},
    {"name":"Palestinian Territory, Occupied","code":"PS"},
    {"name":"Panama","code":"PA"},
    {"name":"Papua New Guinea","code":"PG"},
    {"name":"Paraguay","code":"PY"},
    {"name":"Peru","code":"PE"},
    {"name":"Philippines","code":"PH"},
    {"name":"Pitcairn","code":"PN"},
    {"name":"Poland","code":"PL"},
    {"name":"Portugal","code":"PT"},
    {"name":"Puerto Rico","code":"PR"},
    {"name":"Qatar","code":"QA"},
    {"name":"Reunion","code":"RE"},
    {"name":"Romania","code":"RO"},
    {"name":"Russian Federation","code":"RU"},
    {"name":"Rwanda","code":"RW"},
    {"name":"Saint Barthelemy","code":"BL"},
    {"name":"Saint Helena","code":"SH"},
    {"name":"Saint Kitts and Nevis","code":"KN"},
    {"name":"Saint Lucia","code":"LC"},
    {"name":"Saint Martin","code":"MF"},
    {"name":"Saint Pierre and Miquelon","code":"PM"},
    {"name":"Saint Vincent and the Grenadines","code":"VC"},
    {"name":"Samoa","code":"WS"},
    {"name":"San Marino","code":"SM"},
    {"name":"Sao Tome and Principe","code":"ST"},
    {"name":"Saudi Arabia","code":"SA"},
    {"name":"Senegal","code":"SN"},
    {"name":"Serbia","code":"RS"},
    {"name":"Serbia and Montenegro","code":"CS"},
    {"name":"Seychelles","code":"SC"},
    {"name":"Sierra Leone","code":"SL"},
    {"name":"Singapore","code":"SG"},
    {"name":"Sint Maarten","code":"SX"},
    {"name":"Slovakia","code":"SK"},
    {"name":"Slovenia","code":"SI"},
    {"name":"Solomon Islands","code":"SB"},
    {"name":"Somalia","code":"SO"},
    {"name":"South Africa","code":"ZA"},
    {"name":"South Georgia and the South Sandwich Islands","code":"GS"},
    {"name":"South Sudan","code":"SS"},
    {"name":"Spain","code":"ES"},
    {"name":"Sri Lanka","code":"LK"},
    {"name":"Sudan","code":"SD"},
    {"name":"Suriname","code":"SR"},
    {"name":"Svalbard and Jan Mayen","code":"SJ"},
    {"name":"Swaziland","code":"SZ"},
    {"name":"Sweden","code":"SE"},
    {"name":"Switzerland","code":"CH"},
    {"name":"Syrian Arab Republic","code":"SY"},
    {"name":"Taiwan, Province of China","code":"TW"},
    {"name":"Tajikistan","code":"TJ"},
    {"name":"Tanzania, United Republic of","code":"TZ"},
    {"name":"Thailand","code":"TH"},
    {"name":"Timor-Leste","code":"TL"},
    {"name":"Togo","code":"TG"},
    {"name":"Tokelau","code":"TK"},
    {"name":"Tonga","code":"TO"},
    {"name":"Trinidad and Tobago","code":"TT"},
    {"name":"Tunisia","code":"TN"},
    {"name":"Turkey","code":"TR"},
    {"name":"Turkmenistan","code":"TM"},
    {"name":"Turks and Caicos Islands","code":"TC"},
    {"name":"Tuvalu","code":"TV"},
    {"name":"Uganda","code":"UG"},
    {"name":"Ukraine","code":"UA"},
    {"name":"United Arab Emirates","code":"AE"},
    {"name":"United Kingdom","code":"GB"},
    {"name":"United States","code":"US"},
    {"name":"United States Minor Outlying Islands","code":"UM"},
    {"name":"Uruguay","code":"UY"},
    {"name":"Uzbekistan","code":"UZ"},
    {"name":"Vanuatu","code":"VU"},
    {"name":"Venezuela","code":"VE"},
    {"name":"Viet Nam","code":"VN"},
    {"name":"Virgin Islands, British","code":"VG"},
    {"name":"Virgin Islands, U.s.","code":"VI"},
    {"name":"Wallis and Futuna","code":"WF"},
    {"name":"Western Sahara","code":"EH"},
    {"name":"Yemen","code":"YE"},
    {"name":"Zambia","code":"ZM"},
    {"name":"Zimbabwe","code":"ZW"}
];
}
function Listplatform(){
  return [
      {
          label:'Android'
      },
      {
          label:'IOS'
      }
  ];
}
function Listdevice(){
    return [
        {
            label:'Android TV'
        },
        {
            label:'Android Phone'
        },
        {
            label:'iphone'
        },
        {
            label:'PC/MAC'
        },
        {
            label:'AppleTV'
        }
    ];
}
const Listgenre = [
    {
        label:'Romance',
        value:'Romance'
    },
    {
        label:'Action',
        value:'Action'
    },
    {   
        label:'Thriller',
        value:'Thriller'
    }, 
    {  
        label:'Suspense',
        value:'Suspense'
    },
    {
        label:'Murder',
        value:'Murder'
    },
    {
      label:'Adults',
      value:'Adults'
    },
    {
      label:'Drama',
      value:'Drama'
    },
    {
      label:'Comedy',
      value:'Comedy'
    },
    {
      label:'Horror',
      value:'Horror'
    },
]

function Opt(){
    return[
        {
          value:0,
          label:'Graph'
        },
        {
          value:1,
          label:'Data'
        }
      ]
}
function Listperiod(){
    return[
    {
      id:0,
      Name:'Daily',
      value:'day',
      icon:<AddchartIcon fontSize="small" className="icon"/>
    },
    {
      id:1,
      Name:'Weekly',
      value:'week',
      icon:<AddchartIcon fontSize="small" className="icon"/>
    },
    {
      id:2,
      Name:'Biweekly',
      value:'biweek',
      icon:<AddchartIcon fontSize="small" className="icon"/>
    },
    {
      id:3,
      Name:'Monthly',
      value:'monthly',
      icon:<AddchartIcon fontSize="small" className="icon"/>
    },
    {
      id:4,
      Name:'Quaterly',
      value:'quaterly',
      icon:<AddchartIcon fontSize="small" className="icon"/>
    },
    {
      id:5,
      Name:'Annual',
      value:'annual',
      icon:<AddchartIcon fontSize="small" className="icon"/>
    },
    {
      id:6,
      Name:'Lifetime',
      value:'lifetime',
      icon:<AddchartIcon fontSize="small" className="icon"/>
    },
    {
      id:7,
      Name:'Current Day',
      value:'currentday',
      icon:<AddchartIcon fontSize="small" className="icon"/>
    },
    {
      id:8,
      Name:'Current Week',
      value:'currentweek',
      icon:<AddchartIcon fontSize="small" className="icon"/>
    }
  ]
}

const countries = [
  {"label":"Afghanistan","value":"AF"},
  {"label":"Aland Islands","value":"AX"},
  {"label":"Albania","value":"AL"},
  {"label":"Algeria","value":"DZ"},
  {"label":"American Samoa","value":"AS"},
  {"label":"Andorra","value":"AD"},
  {"label":"Angola","value":"AO"},
  {"label":"Anguilla","value":"AI"},
  {"label":"Antarctica","value":"AQ"},
  {"label":"Antigua and Barbuda","value":"AG"},
  {"label":"Argentina","value":"AR"},
  {"label":"Armenia","value":"AM"},
  {"label":"Aruba","value":"AW"},
  {"label":"Australia","value":"AU"},
  {"label":"Austria","value":"AT"},
  {"label":"Azerbaijan","value":"AZ"},
  {"label":"Bahamas","value":"BS"},
  {"label":"Bahrain","value":"BH"},
  {"label":"Bangladesh","value":"BD"},
  {"label":"Barbados","value":"BB"},
  {"label":"Belarus","value":"BY"},
  {"label":"Belgium","value":"BE"},
  {"label":"Belize","value":"BZ"},
  {"label":"Benin","value":"BJ"},
  {"label":"Bermuda","value":"BM"},
  {"label":"Bhutan","value":"BT"},
  {"label":"Bolivia","value":"BO"},
  {"label":"Bonaire, Sint Eustatius and Saba","value":"BQ"},
  {"label":"Bosnia and Herzegovina","value":"BA"},
  {"label":"Botswana","value":"BW"},
  {"label":"Bouvet Island","value":"BV"},
  {"label":"Brazil","value":"BR"},
  {"label":"British Indian Ocean Territory","value":"IO"},
  {"label":"Brunei Darussalam","value":"BN"},
  {"label":"Bulgaria","value":"BG"},
  {"label":"Burkina Faso","value":"BF"},
  {"label":"Burundi","value":"BI"},
  {"label":"Cambodia","value":"KH"},
  {"label":"Cameroon","value":"CM"},
  {"label":"Canada","value":"CA"},
  {"label":"Cape Verde","value":"CV"},
  {"label":"Cayman Islands","value":"KY"},
  {"label":"Central African Republic","value":"CF"},
  {"label":"Chad","value":"TD"},
  {"label":"Chile","value":"CL"},
  {"label":"China","value":"CN"},
  {"label":"Christmas Island","value":"CX"},
  {"label":"Cocos (Keeling) Islands","value":"CC"},
  {"label":"Colombia","value":"CO"},
  {"label":"Comoros","value":"KM"},
  {"label":"Congo","value":"CG"},
  {"label":"Congo, Democratic Republic of the Congo","value":"CD"},
  {"label":"Cook Islands","value":"CK"},
  {"label":"Costa Rica","value":"CR"},
  {"label":"Cote D'Ivoire","value":"CI"},
  {"label":"Croatia","value":"HR"},
  {"label":"Cuba","value":"CU"},
  {"label":"Curacao","value":"CW"},
  {"label":"Cyprus","value":"CY"},
  {"label":"Czech Republic","value":"CZ"},
  {"label":"Denmark","value":"DK"},
  {"label":"Djibouti","value":"DJ"},
  {"label":"Dominica","value":"DM"},
  {"label":"Dominican Republic","value":"DO"},
  {"label":"Ecuador","value":"EC"},
  {"label":"Egypt","value":"EG"},
  {"label":"El Salvador","value":"SV"},
  {"label":"Equatorial Guinea","value":"GQ"},
  {"label":"Eritrea","value":"ER"},
  {"label":"Estonia","value":"EE"},
  {"label":"Ethiopia","value":"ET"},
  {"label":"Falkland Islands (Malvinas)","value":"FK"},
  {"label":"Faroe Islands","value":"FO"},
  {"label":"Fiji","value":"FJ"},
  {"label":"Finland","value":"FI"},
  {"label":"France","value":"FR"},
  {"label":"French Guiana","value":"GF"},
  {"label":"French Polynesia","value":"PF"},
  {"label":"French Southern Territories","value":"TF"},
  {"label":"Gabon","value":"GA"},
  {"label":"Gambia","value":"GM"},
  {"label":"Georgia","value":"GE"},
  {"label":"Germany","value":"DE"},
  {"label":"Ghana","value":"GH"},
  {"label":"Gibraltar","value":"GI"},
  {"label":"Greece","value":"GR"},
  {"label":"Greenland","value":"GL"},
  {"label":"Grenada","value":"GD"},
  {"label":"Guadeloupe","value":"GP"},
  {"label":"Guam","value":"GU"},
  {"label":"Guatemala","value":"GT"},
  {"label":"Guernsey","value":"GG"},
  {"label":"Guinea","value":"GN"},
  {"label":"Guinea-Bissau","value":"GW"},
  {"label":"Guyana","value":"GY"},
  {"label":"Haiti","value":"HT"},
  {"label":"Heard Island and Mcdonald Islands","value":"HM"},
  {"label":"Holy See (Vatican City State)","value":"VA"},
  {"label":"Honduras","value":"HN"},
  {"label":"Hong Kong","value":"HK"},
  {"label":"Hungary","value":"HU"},
  {"label":"Iceland","value":"IS"},
  {"label":"India","value":"IN"},
  {"label":"Indonesia","value":"ID"},
  {"label":"Iran, Islamic Republic of","value":"IR"},
  {"label":"Iraq","value":"IQ"},
  {"label":"Ireland","value":"IE"},
  {"label":"Isle of Man","value":"IM"},
  {"label":"Israel","value":"IL"},
  {"label":"Italy","value":"IT"},
  {"label":"Jamaica","value":"JM"},
  {"label":"Japan","value":"JP"},
  {"label":"Jersey","value":"JE"},
  {"label":"Jordan","value":"JO"},
  {"label":"Kazakhstan","value":"KZ"},
  {"label":"Kenya","value":"KE"},
  {"label":"Kiribati","value":"KI"},
  {"label":"Korea, Democratic People's Republic of","value":"KP"},
  {"label":"Korea, Republic of","value":"KR"},
  {"label":"Kosovo","value":"XK"},
  {"label":"Kuwait","value":"KW"},
  {"label":"Kyrgyzstan","value":"KG"},
  {"label":"Lao People's Democratic Republic","value":"LA"},
  {"label":"Latvia","value":"LV"},
  {"label":"Lebanon","value":"LB"},
  {"label":"Lesotho","value":"LS"},
  {"label":"Liberia","value":"LR"},
  {"label":"Libyan Arab Jamahiriya","value":"LY"},
  {"label":"Liechtenstein","value":"LI"},
  {"label":"Lithuania","value":"LT"},
  {"label":"Luxembourg","value":"LU"},
  {"label":"Macao","value":"MO"},
  {"label":"Macedonia, the Former Yugoslav Republic of","value":"MK"},
  {"label":"Madagascar","value":"MG"},
  {"label":"Malawi","value":"MW"},
  {"label":"Malaysia","value":"MY"},
  {"label":"Maldives","value":"MV"},
  {"label":"Mali","value":"ML"},
  {"label":"Malta","value":"MT"},
  {"label":"Marshall Islands","value":"MH"},
  {"label":"Martinique","value":"MQ"},
  {"label":"Mauritania","value":"MR"},
  {"label":"Mauritius","value":"MU"},
  {"label":"Mayotte","value":"YT"},
  {"label":"Mexico","value":"MX"},
  {"label":"Micronesia, Federated States of","value":"FM"},
  {"label":"Moldova, Republic of","value":"MD"},
  {"label":"Monaco","value":"MC"},
  {"label":"Mongolia","value":"MN"},
  {"label":"Montenegro","value":"ME"},
  {"label":"Montserrat","value":"MS"},
  {"label":"Morocco","value":"MA"},
  {"label":"Mozambique","value":"MZ"},
  {"label":"Myanmar","value":"MM"},
  {"label":"Namibia","value":"NA"},
  {"label":"Nauru","value":"NR"},
  {"label":"Nepal","value":"NP"},
  {"label":"Netherlands","value":"NL"},
  {"label":"Netherlands Antilles","value":"AN"},
  {"label":"New Caledonia","value":"NC"},
  {"label":"New Zealand","value":"NZ"},
  {"label":"Nicaragua","value":"NI"},
  {"label":"Niger","value":"NE"},
  {"label":"Nigeria","value":"NG"},
  {"label":"Niue","value":"NU"},
  {"label":"Norfolk Island","value":"NF"},
  {"label":"Northern Mariana Islands","value":"MP"},
  {"label":"Norway","value":"NO"},
  {"label":"Oman","value":"OM"},
  {"label":"Pakistan","value":"PK"},
  {"label":"Palau","value":"PW"},
  {"label":"Palestinian Territory, Occupied","value":"PS"},
  {"label":"Panama","value":"PA"},
  {"label":"Papua New Guinea","value":"PG"},
  {"label":"Paraguay","value":"PY"},
  {"label":"Peru","value":"PE"},
  {"label":"Philippines","value":"PH"},
  {"label":"Pitcairn","value":"PN"},
  {"label":"Poland","value":"PL"},
  {"label":"Portugal","value":"PT"},
  {"label":"Puerto Rico","value":"PR"},
  {"label":"Qatar","value":"QA"},
  {"label":"Reunion","value":"RE"},
  {"label":"Romania","value":"RO"},
  {"label":"Russian Federation","value":"RU"},
  {"label":"Rwanda","value":"RW"},
  {"label":"Saint Barthelemy","value":"BL"},
  {"label":"Saint Helena","value":"SH"},
  {"label":"Saint Kitts and Nevis","value":"KN"},
  {"label":"Saint Lucia","value":"LC"},
  {"label":"Saint Martin","value":"MF"},
  {"label":"Saint Pierre and Miquelon","value":"PM"},
  {"label":"Saint Vincent and the Grenadines","value":"VC"},
  {"label":"Samoa","value":"WS"},
  {"label":"San Marino","value":"SM"},
  {"label":"Sao Tome and Principe","value":"ST"},
  {"label":"Saudi Arabia","value":"SA"},
  {"label":"Senegal","value":"SN"},
  {"label":"Serbia","value":"RS"},
  {"label":"Serbia and Montenegro","value":"CS"},
  {"label":"Seychelles","value":"SC"},
  {"label":"Sierra Leone","value":"SL"},
  {"label":"Singapore","value":"SG"},
  {"label":"Sint Maarten","value":"SX"},
  {"label":"Slovakia","value":"SK"},
  {"label":"Slovenia","value":"SI"},
  {"label":"Solomon Islands","value":"SB"},
  {"label":"Somalia","value":"SO"},
  {"label":"South Africa","value":"ZA"},
  {"label":"South Georgia and the South Sandwich Islands","value":"GS"},
  {"label":"South Sudan","value":"SS"},
  {"label":"Spain","value":"ES"},
  {"label":"Sri Lanka","value":"LK"},
  {"label":"Sudan","value":"SD"},
  {"label":"Surilabel","value":"SR"},
  {"label":"Svalbard and Jan Mayen","value":"SJ"},
  {"label":"Swaziland","value":"SZ"},
  {"label":"Sweden","value":"SE"},
  {"label":"Switzerland","value":"CH"},
  {"label":"Syrian Arab Republic","value":"SY"},
  {"label":"Taiwan, Province of China","value":"TW"},
  {"label":"Tajikistan","value":"TJ"},
  {"label":"Tanzania, United Republic of","value":"TZ"},
  {"label":"Thailand","value":"TH"},
  {"label":"Timor-Leste","value":"TL"},
  {"label":"Togo","value":"TG"},
  {"label":"Tokelau","value":"TK"},
  {"label":"Tonga","value":"TO"},
  {"label":"Trinidad and Tobago","value":"TT"},
  {"label":"Tunisia","value":"TN"},
  {"label":"Turkey","value":"TR"},
  {"label":"Turkmenistan","value":"TM"},
  {"label":"Turks and Caicos Islands","value":"TC"},
  {"label":"Tuvalu","value":"TV"},
  {"label":"Uganda","value":"UG"},
  {"label":"Ukraine","value":"UA"},
  {"label":"United Arab Emirates","value":"AE"},
  {"label":"United Kingdom","value":"GB"},
  {"label":"United States","value":"US"},
  {"label":"United States Minor Outlying Islands","value":"UM"},
  {"label":"Uruguay","value":"UY"},
  {"label":"Uzbekistan","value":"UZ"},
  {"label":"Vanuatu","value":"VU"},
  {"label":"Venezuela","value":"VE"},
  {"label":"Viet Nam","value":"VN"},
  {"label":"Virgin Islands, British","value":"VG"},
  {"label":"Virgin Islands, U.s.","value":"VI"},
  {"label":"Wallis and Futuna","value":"WF"},
  {"label":"Western Sahara","value":"EH"},
  {"label":"Yemen","value":"YE"},
  {"label":"Zambia","value":"ZM"},
  {"label":"Zimbabwe","value":"ZW"}
];


const Listtags = [
  {"label":"Non Explicit Sexual Behavior","value":"Non Explicit Sexual Behavior"},
  {"label":"Foul Language","value":"Foul Language"},
  {"label":"Violence","value":"Violence"},
  {"label":"May contain violence","value":"May contain violence"},
  {"label":"Strong Language","value":"Strong Language"},
  {"label":"Substances","value":"Substances"},
  {"label":"Nudity","value":"Nudity"},
  {"label":"Sexual content","value":"Sexual content"},
  {"label":"May contain sex","value":"May contain sex"},
  {"label":"Alcohol Consumption","value":"Alcohol Consumption"},
  {"label":"Smoking","value":"Smoking"},
  {"label":"Substance Abuse","value":"Substance Abuse"},
  {"label":"Gore","value":"Gore"},
  {"label":"Self-harm","value":"Self-harm"},
  {"label":"Sexual violence","value":"Sexual violence"},
  {"label":"Tobacco use","value":"Tobacco use"},
  {"label":"Suicide","value":"Suicide"},
];
export {countries,Listcountry,Listplatform,Listdevice,Listgenre,Listperiod,Opt,Listtags};